import React from "react";
import { Flipper } from "react-flip-toolkit";
import { SimpleGrid } from "@chakra-ui/react";
import CompanyCard from "./CompanyCard";
import "../../styles/companyCards.css";
import { hireup, optergy, codecamp } from "../../constants/constants";

const companies = [
  {
    companyName: hireup.companyName,
    logoPlaceholder: hireup.logo,
    descriptionPlaceholder: hireup.details,
    weblink: hireup.websiteLink,
    colorGradient: hireup.colorGradient,
    datesWorked: "2022 - present",
    position: "Senior Software Engineer",
  },
  {
    companyName: optergy.companyName,
    logoPlaceholder: optergy.logo,
    descriptionPlaceholder: optergy.details,
    weblink: optergy.websiteLink,
    colorGradient: optergy.colorGradient,
    datesWorked: "2019 - 2022",
    position: "Software Engineer",
  },
  {
    companyName: codecamp.companyName,
    logoPlaceholder: codecamp.logo,
    descriptionPlaceholder: codecamp.details,
    weblink: codecamp.websiteLink,
    colorGradient: codecamp.colorGradient,
    datesWorked: "2016 - 2019",
    position: "Head Teacher",
  },
];

const CompanyCards: React.FC = () => {
  return (
    <Flipper flipKey={Date.now()} spring={{ stiffness: 60, damping: 15 }}>
      <SimpleGrid
        columns={{ base: 1, sm: 2, md: 3 }}
        spacing={{ base: 5, md: 8 }}
        width="100%"
      >
        {companies.map((company) => (
          <CompanyCard key={company.companyName} {...company} />
        ))}
      </SimpleGrid>
    </Flipper>
  );
};

export default CompanyCards;

import React from "react";
import { BsFillSuitHeartFill } from "react-icons/bs";
import { GiAustralia } from "react-icons/gi";
import { Flex, HStack, Icon, Image, Text, Box } from "@chakra-ui/react";
import "../styles/footer.css";
import sigFlat from "../assets/images/sigflat.png";
import { name } from "../constants/personalInfo";

const Footer = () => {
  const year = new Date().getFullYear();
  return (
    <Box id="footer">
      <Flex
        className="footer"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        padding="2rem"
      >
        <HStack>
          <Text fontSize="md" color="black">
            Made with{" "}
          </Text>
          <Icon as={BsFillSuitHeartFill} color="red" boxSize="20px" />
          <Text fontSize="md" color="black">
            In{" "}
          </Text>
          <Icon as={GiAustralia} color="black" boxSize="20px" />
          <Text fontSize="md" color="black">
            By{" "}
          </Text>
          <Image src={sigFlat} boxSize="40px" />
        </HStack>
        <Text fontSize="10px" color="black">
          Using ReactJs and Vercel.
        </Text>
        <Text fontSize="10px" color="black" mb={3}>
          © {year} {name.fullName} All Rights Reserved.
        </Text>
      </Flex>
    </Box>
  );
};

export default Footer;

import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { ChakraProvider } from "@chakra-ui/react";
import theme from "./theme/theme";
import Home from "./components/Home";
import "./styles/global.css";
import About from "./components/About";
import Experience from "./components/Experience";
import Projects from "./components/Projects";
import Skills from "./components/Skills";
import Contact from "./components/Contact";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";

const App: React.FC = () => {
  return (
    <ChakraProvider theme={theme}>
      <Router>
        <Navbar />
        <Home />
        <About />
        <Experience />
        <Skills />
        <Projects />
        <Contact />
        <Footer />
      </Router>
    </ChakraProvider>
  );
};

export default App;

// src/components/Projects.tsx

import React from "react";
import {
  Box,
  Flex,
  Heading,
  Text,
  Image,
  Stack,
  Tag,
  useColorModeValue,
  Container,
  Button,
  Link,
} from "@chakra-ui/react";
import { projects } from "../constants/project";
import { style2 } from "../constants/constants";
import { ExternalLinkIcon } from "@chakra-ui/icons";

const Projects: React.FC = () => {
  const bgColor = useColorModeValue("gray.50", "gray.800");

  return (
    <Box id="projects" py={10} bgGradient={style2}>
      <Container maxW="container.xl" px={5}>
        <Heading
          fontSize={{ base: "4xl", md: "6xl" }}
          textAlign="center"
          mt={{ base: 10, md: 20 }}
          mb={10}
        >
          Projects
        </Heading>

        <Stack spacing={10}>
          {projects.map((project, index) => (
            <Flex
              key={project.id}
              direction={{ base: "column", md: "row" }}
              align="center"
              bg={bgColor}
              p={5}
              rounded="md"
              boxShadow="md"
              overflow="hidden"
            >
              {/* Image */}
              <Box
                flexShrink={0}
                mb={{ base: 5, md: 0 }}
                mr={{ md: 6 }}
                width={{ base: "100%", md: "40%" }}
              >
                <Image
                  src={project.imageSrc}
                  alt={project.title}
                  objectFit="cover"
                  width="100%"
                  height={{ base: "200px", md: "100%" }}
                  rounded="md"
                />
              </Box>

              {/* Project Info */}
              <Box flex="1">
                <Heading as="h3" fontSize={{ base: "2xl", md: "3xl" }} mb={3}>
                  {project.title}
                </Heading>
                <Text fontSize={{ base: "md", md: "lg" }} mb={3}>
                  {project.description}
                </Text>
                {/* Technologies Used */}
                <Stack direction="row" wrap="wrap" mb={3}>
                  {project.technologies.map((tech) => (
                    <Tag
                      key={tech}
                      size="md"
                      variant="solid"
                      colorScheme="teal"
                      mr={2}
                      mb={2}
                    >
                      {tech}
                    </Tag>
                  ))}
                </Stack>
                {/* Project Link */}
                {project.link && (
                  <Link href={project.link} isExternal>
                    <Button
                      rightIcon={<ExternalLinkIcon />}
                      colorScheme="teal"
                      variant="outline"
                    >
                      View Project
                    </Button>
                  </Link>
                )}
              </Box>
            </Flex>
          ))}
        </Stack>
      </Container>
    </Box>
  );
};

export default Projects;

// Skills.tsx
import React, { useState } from "react";
import {
  Box,
  Heading,
  SimpleGrid,
  VStack,
  List,
  ListItem,
  ListIcon,
  useColorModeValue,
  Text,
  Container,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Button,
} from "@chakra-ui/react";
import { CheckCircleIcon } from "@chakra-ui/icons";
import { skillsData, style1 } from "../constants/constants";
import { Skill, SkillCategory } from "../constants/types";

const Skills: React.FC = () => {
  const [selectedCategory, setSelectedCategory] =
    useState<SkillCategory | null>(null);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleCategoryClick = (categoryKey: string) => {
    setSelectedCategory(skillsData[categoryKey]);
    onOpen();
  };

  return (
    <Box id="skills" py={10} bgGradient={style1} minHeight="100vh">
      <Container maxW="container.md" px={{ base: 5, md: 10 }}>
        <Heading
          fontSize={{ base: "4xl", md: "6xl" }}
          textAlign="center"
          mt={25}
          mb={4}
        >
          Skills
        </Heading>
        <Text
          textAlign="center"
          maxW="600px"
          mx="auto"
          mb={4}
          color={useColorModeValue("gray.600", "gray.300")}
          fontSize={{ base: "md", md: "lg", lg: "xl" }}
          pt={{ base: 5, md: 10 }}
        >
          I started out as a backend engineer and developed a love for all
          things backend. That being said, over the years {"I've"} developed my
          frontend skills and am now comfortable with it.
        </Text>

        <Text
          textAlign="center"
          maxW="600px"
          mx="auto"
          mb={10}
          color={useColorModeValue("gray.600", "gray.300")}
          fontSize={{ base: "sm", md: "md", lg: "lg" }}
          pt={{ base: 2, md: 5 }}
        >
          Working in the disability sector, {"I've"} also learned how important
          accessible design is. I also have experience with security and load
          testing.
        </Text>

        {/* Skills Grid */}
        <SimpleGrid
          columns={1} // Single column
          spacing={{ base: 5, md: 8 }}
          pt={{ base: 5, md: 10 }}
        >
          {Object.keys(skillsData).map((key) => {
            const category = skillsData[key];
            return (
              <Box key={key} textAlign="center">
                <Button
                  variant="outline"
                  colorScheme="teal"
                  size="lg"
                  onClick={() => handleCategoryClick(key)}
                  width={{ base: "80%", md: "300px" }} // Set consistent width
                  maxWidth="300px" // Ensure max width
                >
                  {category.category}
                </Button>
              </Box>
            );
          })}
        </SimpleGrid>
      </Container>

      {/* Modal for displaying skills */}
      {selectedCategory && (
        <Modal isOpen={isOpen} onClose={onClose} isCentered size="sm">
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>{selectedCategory.category}</ModalHeader>
            <ModalCloseButton />
            <ModalBody pb={6}>
              {" "}
              {/* Add padding-bottom */}
              <List spacing={3}>
                {selectedCategory.skills.map((skill: Skill) => (
                  <ListItem key={skill.name}>
                    <ListIcon as={CheckCircleIcon} color="teal.400" />
                    <Text as="span" fontWeight="medium">
                      {skill.name}
                    </Text>
                    {skill.level && (
                      <Text as="span" fontSize="sm" color="gray.500">
                        {" - " + skill.level}
                      </Text>
                    )}
                  </ListItem>
                ))}
              </List>
            </ModalBody>
          </ModalContent>
        </Modal>
      )}
    </Box>
  );
};

export default Skills;

import React from "react";
import { Tab, Icon } from "@chakra-ui/react";
import { Link as ScrollLink } from "react-scroll";

interface ScrollProps {
  to: string;
  label: string;
  icon?: any;
  index: number;
  setActiveIndex: (index: number) => void;
}

const ScrollComponent: React.FC<ScrollProps> = ({
  to,
  label,
  icon,
  index,
  setActiveIndex,
}) => {
  const handleSetActive = () => {
    setActiveIndex(index);
  };

  return (
    <ScrollLink
      to={to}
      smooth={true}
      duration={500}
      spy={true}
      offset={-70}
      onSetActive={handleSetActive}
    >
      <Tab>
        {React.isValidElement(icon) ? (
          icon
        ) : icon ? (
          <Icon as={icon} boxSize="1.5em" />
        ) : (
          label
        )}
      </Tab>
    </ScrollLink>
  );
};

export default ScrollComponent;

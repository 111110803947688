import portfolio from "../assets/images/portfolio.webp";
import sidescroller from "../assets/images/sidescroll.jpg";
import d3 from "../assets/images/3d.gif";

export interface Project {
  id: number;
  title: string;
  description: string;
  technologies: string[];
  imageSrc: string;
  link?: string; // Optional: Link to project or repository
}

export const projects: Project[] = [
  {
    id: 1,
    title: "This Portfolio",
    description:
      "This is a Hobby Project that I intend to maintain and add more to. It's also a mini showcase " +
      "of the things I have picked up in the Frontend land!",
    technologies: [
      "React",
      "TypeScript",
      "Chakra UI",
      "Framer Motion",
      "Vercel",
    ],
    imageSrc: portfolio,
    link: "https://github.com/amansh95/Portfolio",
  },
  {
    id: 2,
    title: "Project Astro",
    description: "A simple 2D side scrolling game with various hurdles",
    technologies: ["Java", "Shell"],
    imageSrc: sidescroller,
    link: "https://github.com/amansh95/2911-2D-Game",
  },
  {
    id: 3,
    title: "3D World",
    description:
      "A basic 3D rendered world that includes movable camera, lighting, foliage etc",
    technologies: ["Java", "Java OpenGL"],
    imageSrc: d3,
    link: "https://github.com/amansh95/3421-3D-world",
  },
];

import React from "react";
import { Icon, Link } from "@chakra-ui/react";
import { AllowedIcons, IconColorType } from "../../constants/types";
import { IconMap, SocialLinksMap } from "../../constants/constants";
import { MotionBox } from "./motion-chakra";

interface NavbarItemProps {
  iconType: AllowedIcons;
  key?: number;
}

const colorMap: IconColorType = {
  github: "#2b2d42",
  instagram: "#ef233c",
  linkedIn: "#0077b6",
  email: "#e07a5f",
};

const IconWithLink: React.FC<NavbarItemProps> = ({ iconType, key }) => {
  return (
    <MotionBox
      key={key}
      whileHover={{
        scale: 1.2,
        rotate: 10,
        transition: { type: "spring", stiffness: 300 },
      }}
    >
      <Link href={SocialLinksMap[iconType]}>
        <Icon
          as={IconMap[iconType]}
          focusable="true"
          boxSize="1.5em"
          color={colorMap[iconType]}
        />
      </Link>
    </MotionBox>
  );
};

export default IconWithLink;

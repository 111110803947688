import React from "react";
import {
  Flex,
  Heading,
  Box,
  Link,
  Text,
  Image,
  List,
  ListItem,
  ListIcon,
} from "@chakra-ui/react";
import hi from "../assets/images/hi.gif";
import { FaSmileBeam } from "react-icons/fa";
import { FaFaceSadCry } from "react-icons/fa6";
import { style1 } from "../constants/constants";

const About: React.FC = () => {
  const gifSize = "100px";
  const headingSpacing = { base: 10, md: 100 };

  return (
    <Box
      id="about"
      bgGradient={style1}
      minHeight="100vh"
      display="flex"
      alignItems="center"
      justifyContent="center"
      p={6}
    >
      <Flex
        flexDirection={{ base: "column", md: "row" }}
        alignItems="center"
        justifyContent="center"
        maxWidth="1200px"
        width="100%"
        flexWrap="wrap"
      >
        <Image
          src={hi}
          alt="hi"
          boxSize={gifSize}
          objectFit="cover"
          mb={{ base: 4, md: 0 }}
          mr={{ md: 8 }}
          transform={{ base: "none", md: "translateY(-130px)" }}
        />
        <Box
          textAlign="center"
          display="flex"
          flexDirection="column"
          alignItems="center"
        >
          <Heading fontSize={{ base: "4xl", md: "6xl" }} mb={headingSpacing}>
            About me
          </Heading>
          <Flex
            flexDirection="column"
            alignItems="center"
            textAlign="center"
            maxWidth="600px"
            width="100%"
            px={4}
          >
            <Text fontSize="2xl" mb={4}>
              Hi, I am Aman Shekhar (अमन शेखर), a Senior Software Engineer with
              a passion for learning new things, be it code or not, and
              exploring the unknown. In my free time, you’ll find me diving into{" "}
              <Link
                href="https://www.suckerpunch.com/category/games/ghostoftsushima/"
                color="teal.500"
                isExternal
              >
                video games
              </Link>
              , experimenting with{" "}
              <Link
                href="https://www.jambojamborestaurant.com.au/"
                color="teal.500"
                isExternal
              >
                new cuisines
              </Link>
              , or on the court playing{" "}
              <Link
                href="https://bwfbadminton.com/player/89916/praveen-jordan"
                color="teal.500"
                isExternal
              >
                badminton
              </Link>
              .
            </Text>
            <Text fontSize="2xl">
              When I get some free time, I love to take on hobbies to varying
              degrees of success. {"Here's"} a list of things that I have found
              myself a fan of, and some that I {"haven't"}
            </Text>
            <List spacing={3} mt={10}>
              <ListItem display="flex" alignItems="center" fontSize="xl">
                <ListIcon as={FaSmileBeam} color="green.500" />
                Legos
              </ListItem>
              <ListItem display="flex" alignItems="center" fontSize="xl">
                <ListIcon as={FaSmileBeam} color="green.500" />
                Building PCs and Keyboards
              </ListItem>
              <ListItem display="flex" alignItems="center" fontSize="xl">
                <ListIcon as={FaSmileBeam} color="green.500" />
                Kayaking
              </ListItem>
              <ListItem display="flex" alignItems="center" fontSize="xl">
                <ListIcon as={FaSmileBeam} color="green.500" />
                Go Karting
              </ListItem>
              <ListItem display="flex" alignItems="center" fontSize="xl">
                <ListIcon as={FaFaceSadCry} color="red.500" />
                Skiing and Ice Skating
              </ListItem>
              <ListItem display="flex" alignItems="center" fontSize="xl">
                <ListIcon as={FaFaceSadCry} color="red.500" />
                Pottery
              </ListItem>
              <ListItem display="flex" alignItems="center" fontSize="xl">
                <ListIcon as={FaFaceSadCry} color="red.500" />
                Crochet
              </ListItem>
            </List>
          </Flex>
        </Box>
      </Flex>
    </Box>
  );
};

export default About;

import { FaInstagram } from "react-icons/fa6";
import { FaGithub, FaLinkedinIn } from "react-icons/fa";
import { HiOutlineMail } from "react-icons/hi";
import { IconMapType, SkillsData, SocialLinksMapType } from "./types";
import { email } from "./personalInfo";
import hireupLogo from "../assets/images/hireup.png";
import optergyLogo from "../assets/images/optergy_logo.jpeg";
import codecampLogo from "../assets/images/codecamp.png";

const colorA = "white";
const colorB = "seashell";
const style1 = `linear(to top, ${colorA}, ${colorB})`;
const style2 = `linear(to top, ${colorB}, ${colorA})`;

const IconMap: IconMapType = {
  instagram: FaInstagram,
  linkedIn: FaLinkedinIn,
  email: HiOutlineMail,
  github: FaGithub,
};

const SocialLinksMap: SocialLinksMapType = {
  instagram: "https://www.instagram.com/oneaman95/",
  linkedIn: "https://www.linkedin.com/in/amanshekhar995/",
  email: `mailto:${email}`,
  github: "https://github.com/amansh95",
};

const hireupInfo =
  "Hireup is an online platform that empowers people with disabilities to find, hire, and manage their own support workers. " +
  "As a platform designed to give users control over their support. " +
  "With a mission to revolutionize the disability support sector, Hireup fosters a community-driven approach to providing accessible, quality care";

const hireup = {
  companyName: "Hireup",
  logo: hireupLogo,
  details: hireupInfo,
  websiteLink: "https://hireup.com.au/",
  colorGradient: "linear(to top, #f7ede2, #caf0f8)",
};

const optergyInfo =
  "Optergy is a building automation company specializing in smart solutions for managing and optimizing building performance. " +
  "Their technology integrates systems such as HVAC, lighting, energy management, and access control into a unified platform, allowing for enhanced efficiency, " +
  "comfort, and sustainability";

const optergy = {
  companyName: "Optergy",
  logo: optergyLogo,
  details: optergyInfo,
  websiteLink: "https://optergy.com/",
  colorGradient: "linear(to top, #f7ede2, #b7e4c7)",
};

const codecampInfo =
  "Code Camp is where parents find fun school holiday and after-school activities, " +
  "that empower your kids to design, code and create, and spark a passion, " +
  "so they can go and change the world with a smile on their face.";

const codecamp = {
  companyName: "Code Camp",
  logo: codecampLogo,
  details: codecampInfo,
  websiteLink: "https://www.codecamp.com.au/",
  colorGradient: "linear(to top, #f7ede2, #5aa9e6)",
};

const skillsData: SkillsData = {
  Frontend: {
    category: "Frontend",
    skills: [
      { name: "React", level: "Intermediate" },
      { name: "Vue.js", level: "Intermediate" },
      { name: "Chakra UI", level: "Intermediate" },
      { name: "HTML5", level: "Advanced" },
      { name: "CSS3", level: "Advanced" },
    ],
  },
  Backend: {
    category: "Backend",
    skills: [
      { name: "Node.js", level: "Advanced" },
      { name: "Express.js", level: "Advanced" },
      { name: "Koa.js", level: "Intermediate" },
      { name: "Spring Boot", level: "Beginner" },
    ],
  },
  Languages: {
    category: "Languages",
    skills: [
      { name: "TypeScript", level: "Advanced" },
      { name: "JavaScript", level: "Advanced" },
      { name: "Java", level: "Advanced" },
      { name: "Python", level: "Intermediate" },
      { name: "Elixir", level: "Beginner" },
    ],
  },
  CI_CD: {
    category: "CI/CD",
    skills: [
      { name: "Docker", level: "Advanced" },
      { name: "Terraform", level: "Advanced" },
      { name: "Buildkite", level: "Intermediate" },
      { name: "Octopus Deploy", level: "Intermediate" },
      { name: "GitHub Actions", level: "Intermediate" },
      { name: "Jenkins", level: "Intermediate" },
    ],
  },
  Testing: {
    category: "Testing",
    skills: [
      { name: "Mocha", level: "Intermediate" },
      { name: "Chai", level: "Intermediate" },
      { name: "Jest", level: "Advanced" },
      { name: "Selenium", level: "Intermediate" },
    ],
  },
};

export {
  IconMap,
  SocialLinksMap,
  hireup,
  optergy,
  codecamp,
  skillsData,
  colorA,
  colorB,
  style1,
  style2,
};

import React from "react";
import { Variants } from "framer-motion";
import { MotionBox } from "./motion-chakra";

const scrollVariants: Variants = {
  animate: {
    y: [0, 10, 0],
    opacity: [1, 0.5, 1],
    transition: {
      y: {
        repeat: Infinity,
        duration: 1.5,
        ease: "easeInOut",
      },
      opacity: {
        repeat: Infinity,
        duration: 1.5,
        ease: "easeInOut",
      },
    },
  },
};

const ScrollIndicator: React.FC = () => {
  return (
    <MotionBox
      position="absolute"
      bottom="20px"
      left="50%"
      transform="translateX(-50%)"
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      paddingBottom="50px"
    >
      {/* Mouse Icon */}
      <MotionBox
        width="24px"
        height="40px"
        border="2px solid"
        borderColor="gray.600"
        borderRadius="14px"
        position="relative"
        overflow="hidden"
        paddingBottom="40px"
      >
        {/* Scroll Wheel */}
        <MotionBox
          width="4px"
          height="8px"
          bg="gray.600"
          borderRadius="2px"
          position="absolute"
          top="10px"
          left="50%"
          transform="translateX(-50%)"
          variants={scrollVariants}
          animate="animate"
        />
      </MotionBox>
      {/* "Scroll down" Text */}
      <MotionBox
        mt="10px"
        color="gray.600"
        fontSize="sm"
        variants={scrollVariants}
        animate="animate"
        paddingBottom="50px"
      >
        Scroll down
      </MotionBox>
    </MotionBox>
  );
};

export default ScrollIndicator;

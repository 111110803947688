import React, { useState } from "react";
import { Flipped } from "react-flip-toolkit";
import { Box, Text, Image, IconButton, Link, Flex } from "@chakra-ui/react";
import { CloseIcon } from "@chakra-ui/icons";

interface CompanyCardProps {
  companyName: string;
  logoPlaceholder: string;
  descriptionPlaceholder: string;
  weblink: string;
  datesWorked: string;
  position: string;
  colorGradient: string;
}

const CompanyCard: React.FC<CompanyCardProps> = ({
  companyName,
  logoPlaceholder,
  descriptionPlaceholder,
  weblink,
  datesWorked,
  position,
  colorGradient,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    setIsOpen(!isOpen);
  };

  return (
    <Flipped flipId={companyName}>
      <Box className="card" onClick={handleClick}>
        <Flipped inverseFlipId={companyName}>
          <Box className="card-content">
            {/* Collapsed View */}
            <Box
              className="card-front"
              style={{
                opacity: isOpen ? 0 : 1,
                pointerEvents: isOpen ? "none" : "auto",
              }}
              bg="white"
              borderRadius="10px"
              overflow="hidden"
              boxShadow="md"
              width="100%"
              height="100%"
              display="flex"
              flexDirection="column"
              alignItems="center"
            >
              <Image
                src={logoPlaceholder}
                alt={`${companyName} Logo`}
                boxSize={{ base: "60px", md: "80px" }}
                objectFit="contain"
                mt="4"
              />
              <Box p="4" textAlign="center">
                <Text fontSize={{ base: "xl", md: "2xl" }} fontWeight="bold">
                  {companyName}
                </Text>
                <Text fontSize={{ base: "md", md: "xl" }}>{position}</Text>
                <Text fontSize={{ base: "sm", md: "lg" }} color="gray.500">
                  {datesWorked}
                </Text>
              </Box>
            </Box>

            {/* Expanded View */}
            <Box
              className="card-expanded"
              style={{
                opacity: isOpen ? 1 : 0,
                pointerEvents: isOpen ? "auto" : "none",
              }}
              position="fixed"
              top="0"
              left="0"
              width="100%"
              height="100%"
              zIndex="1000"
              overflowY="auto"
              bgGradient={colorGradient}
            >
              <IconButton
                aria-label="Close"
                icon={<CloseIcon />}
                position="absolute"
                top="20px"
                right="20px"
                onClick={handleClick}
                zIndex="1001"
              />
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                padding={{ base: "1rem", md: "2rem" }}
                overflow="hidden"
              >
                <Image
                  src={logoPlaceholder}
                  alt={`${companyName} Logo`}
                  boxSize={{ base: "100px", md: "150px" }}
                  objectFit="contain"
                  mb="4"
                />
                <Flex
                  flexDirection="column"
                  alignItems="center"
                  textAlign="center"
                  maxWidth="600px"
                  width="100%"
                  px={4}
                >
                  <Text fontSize={{ base: "2xl", md: "3xl" }} fontWeight="bold">
                    {companyName}
                  </Text>
                  <Text fontSize={{ base: "lg", md: "xl" }} mb="2">
                    {position}
                  </Text>
                  <Text fontSize={{ base: "md", md: "lg" }} color="gray.500">
                    {datesWorked}
                  </Text>
                  <Text
                    mt="4"
                    textAlign="center"
                    fontSize={{ base: "xl", md: "3xl" }}
                    px={{ base: 2, md: 4 }}
                  >
                    {descriptionPlaceholder}
                  </Text>
                  <Link href={weblink} marginY="50px">
                    Learn more about {companyName}
                  </Link>
                </Flex>
              </Box>
            </Box>
          </Box>
        </Flipped>
      </Box>
    </Flipped>
  );
};

export default CompanyCard;
